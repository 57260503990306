<template>
  <div class="dark:divide-neutral-dark-1 divide-neutral-light-1 divide-y">
    <ProgramMovieSkeleton
      v-for="(_, key) in skeletons"
      :key
      class="pb-6 pt-8"
    />

    <ProgramMovie
      v-for="{ movie, uuid, flags, showGroups } in groups"
      :key="uuid"
      :uuid
      :movie
      :flags
      :show-cinema-names
      :show-groups
      :playtimes-layout
    />
  </div>
</template>

<script lang="ts" setup>
import type { ProgramGroupByMovie } from '#gql/default'

interface Props {
  groups?: ProgramGroupByMovie[]
  playtimesLayout?: PlaytimesLayout
  renderSkeletons?: number
  showCinemaNames?: boolean
}

const props = defineProps<Props>()
const skeletons = computed(() =>
  Array.from({ length: props.renderSkeletons ?? 0 })
)

defineOptions({
  name: 'ProgramGroupedByMovie',
})
</script>
